import "./src/styles/reset.css";
import "./src/styles/sidebar.css";
import "./src/styles/index.css";
import "./src/styles/hero.css";
import "./src/styles/navbar.css";
import "./src/styles/info.css";
import "./src/styles/about.css";
import "./src/styles/projects.css";
import "./src/styles/contact.css";
import "./src/styles/transition.css";
import "./src/styles/media.css";